import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchInvoices', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownUserAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownUserAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownCompanyAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownCompanyAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
